


































































import { defineComponent, PropType } from '@vue/composition-api';
import { SimpleModel } from '@/models/base/SimpleModel';

export default defineComponent({
  name: 'RdDropdownButton',
  props: {
    buttonText: {
      type: String,
      required: true,
    },
    actions: {
      type: Array as PropType<SimpleModel[]>,
      required: true,
    },
    fullWidth: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['click', 'menu-click'],
  setup: (props, ctx) => {
    const click = (e: MouseEvent) => {
      ctx.emit('click', e);
    };

    const menuClick = (action: SimpleModel) => {
      ctx.emit('menu-click', action);
    };

    const classes = {
      'rd-full-width': props.fullWidth,
    };

    return { classes, click, menuClick };
  },
});
