











import { defineComponent } from '@vue/composition-api';
import { Indexable } from '@/helpers/Indexable';

export default defineComponent({
  name: 'RdLeftRightFlexContent',
  props: {
    leftSideGrow: {
      type: Boolean,
      required: false,
      default: true,
    },
    rightSideGrow: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup: (props) => {
    const leftStyle: Indexable<any> = {};
    const rightStyle: Indexable<any> = {};

    if (props.leftSideGrow) {
      leftStyle['flex-grow'] = 1;
    } else {
      leftStyle['flex-grow'] = 0;
    }
    if (props.rightSideGrow) {
      rightStyle['flex-grow'] = 1;
    } else {
      rightStyle['flex-grow'] = 0;
    }

    return {
      leftStyle,
      rightStyle,
    };
  },
});
