import { render, staticRenderFns } from "./LocomotivesOnMap.vue?vue&type=template&id=4f549a07&scoped=true&"
import script from "./LocomotivesOnMap.vue?vue&type=script&lang=ts&"
export * from "./LocomotivesOnMap.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f549a07",
  null
  
)

export default component.exports