





































































import {
  defineComponent, PropType, reactive, ref,
} from '@vue/composition-api';
import dateFormats from '@/helpers/DateFormats';
import RdDropdownButton from '@/components/base/buttons/RdDropdownButton.vue';
import { SimpleModel } from '@/models/base/SimpleModel';
import OrderCard from '@/components/smart-dispatcher/OrderCard.vue';
import { OrderGroup } from '@/models/orders/OrderGroup';
import RdArrow from '@/components/base/RdArrow.vue';
import RdDragScrollable from '@/components/base/scrollable/RdDragScrollable.vue';
import RdLeftRightFlexContent from '@/components/base/RdLeftRightFlexContent.vue';
import OrderGroupTravelLine from '@/components/smart-dispatcher/TravelLine.vue';

// TODO Доделать этот компонент
export default defineComponent({
  name: 'OrderGroupCard',
  components: {
    OrderGroupTravelLine, RdLeftRightFlexContent, RdDragScrollable, RdArrow, OrderCard, RdDropdownButton,
  },
  props: {
    orderGroup: {
      type: Object as PropType<OrderGroup>,
      required: true,
    },
  },
  setup: () => {
    const { toDate, toTime } = dateFormats();

    const actions = reactive([
      new SimpleModel({
        id: 'ReCreate',
        name: 'Пересоздать',
      }),
    ]);

    const getCustomerName = (orderGroup: OrderGroup) => {
      return orderGroup.businessContract.customer!.name;
    };

    const getOrderGroupDate = (orderGroup: OrderGroup) => {
      return toDate(orderGroup.dateBegin);
    };

    const getContractNumber = (orderGroup: OrderGroup) => {
      return orderGroup.businessContract.number;
    };

    return {
      actions,
      getCustomerName,
      getOrderGroupDate,
      getContractNumber,
      toTime,
      toDate,
    };
  },
});
