import { Type } from 'class-transformer';
import { SimpleModel } from '@/models/base/SimpleModel';
import { Locomotive } from '@/models/Locomotive';
import { LocomotiveCrew } from '@/models/LocomotiveCrew';
import { WorkTask } from '@/models/orders/WorkTask';
import { CrewTripPlan } from '@/models/orders/CrewTripPlan';
import { EmployeeInCrew } from '@/models/trip/EmployeeInCrew';

export class WorkOrder {
  public id!: string;

  @Type(() => Date)
  public dateBegin!: Date;

  @Type(() => Date)
  public dateEnd!: Date;

  public trainNumber!: string;

  @Type(() => SimpleModel)
  public from!: SimpleModel;

  @Type(() => SimpleModel)
  public to!: SimpleModel;

  @Type(() => Locomotive)
  public locomotive!: Locomotive;

  @Type(() => EmployeeInCrew)
  public crew = Array<EmployeeInCrew>();

  @Type(() => SimpleModel)
  public order!: SimpleModel;

  @Type(() => CrewTripPlan)
  public crewTripPlan: CrewTripPlan | null = null;

  @Type(() => WorkTask)
  public workTasks: WorkTask[] = [];

  constructor(init?: Partial<WorkOrder>) {
    Object.assign(this, init);
  }
}
