import { Depot } from '@/models/Depot';
import { Driver } from '@/models/Driver';

export class LocomotiveCrew {
  public id!: string

  public depot!: Depot

  public driver!: Driver

  public assistant?: Driver | null

  public thirdPerson?: Driver | null

  constructor(init?: Partial<LocomotiveCrew>) {
    Object.assign(this, init);
  }
}
