











































































































import { defineComponent, PropType, ref } from '@vue/composition-api';
import { SmartDispatcherLocomotiveModel } from '@/models/smart-dispatcher/SmartDispatcherLocomotiveModel';
import { format } from 'date-fns';
import dateFormats from '@/helpers/DateFormats';

export default defineComponent({
  name: 'LocomotiveCard',
  props: {
    locomotiveInfo: {
      type: Object as PropType<SmartDispatcherLocomotiveModel>,
      required: true,
    },
  },
  setup: (props, ctx) => {
    const { toFullDateTime } = dateFormats();

    const locomotiveWorkSectionLabel = (locomotiveInfo: SmartDispatcherLocomotiveModel) => {
      if (locomotiveInfo.section == null) {
        // TODO 'Не в работе'
        return '';
      }

      // TODO что кроме принят может быть?
      return `${locomotiveInfo.section.name} (принят)`;
    };

    const locomotiveWorkLabel = (locomotiveInfo: SmartDispatcherLocomotiveModel) => {
      if (locomotiveInfo.work == null) {
        // TODO 'Не в работе'
        return '';
      }

      // TODO что кроме В работе может быть?
      return `В работе (${toFullDateTime(locomotiveInfo.work.workStartTime)})`;
    };

    const workCrewLabel = (locomotiveInfo: SmartDispatcherLocomotiveModel) => {
      if (locomotiveInfo.work == null) {
        // TODO 'Не в работе'
        return '';
      }

      const driver = `${locomotiveInfo.work.crew.driver.code} ${locomotiveInfo.work.crew.driver.surname}`;
      const trafficKindName = locomotiveInfo.work.trafficKind.name;
      const depotName = locomotiveInfo.work.crew.depot.name;

      return `${trafficKindName} - ${driver} (${depotName})`;
    };

    const hasWarnings = (locomotiveInfo: SmartDispatcherLocomotiveModel) => {
      return locomotiveInfo.locomotive.locoUnits.filter((lu) => lu.warnings.length > 0);
    };

    return {
      locomotiveWorkSectionLabel,
      locomotiveWorkLabel,
      workCrewLabel,
      hasWarnings,
    };
  },
});
