




















































































































































































































import {
  defineComponent, PropType, reactive, ref,
} from '@vue/composition-api';
import { differenceInMinutes } from 'date-fns';
import dateFormats from '@/helpers/DateFormats';
import RdDropdownButton from '@/components/base/buttons/RdDropdownButton.vue';
import { SimpleModel } from '@/models/base/SimpleModel';
import { WorkOrder } from '@/models/orders/WorkOrder';
import RdArrow from '@/components/base/RdArrow.vue';
import RdDragScrollable from '@/components/base/scrollable/RdDragScrollable.vue';
import RdLeftRightFlexContent from '@/components/base/RdLeftRightFlexContent.vue';

export default defineComponent({
  name: 'WorkOrderCard',
  components: {
    RdLeftRightFlexContent, RdDragScrollable, RdArrow, RdDropdownButton,
  },
  props: {
    workOrder: {
      type: Object as PropType<WorkOrder>,
      required: true,
    },
  },
  setup: (props, ctx) => {
    const { toTime, toDate } = dateFormats();

    const timeLeft = ref(differenceInMinutes(props.workOrder.dateBegin, new Date()));
    const leftPercent = ref(0);

    const actions = [
      new SimpleModel({
        id: 'Cancel',
        name: 'Отмена явки',
      }),
      new SimpleModel({
        id: 'No',
        name: 'Неявка',
      }),
    ];

    const checkTimeDiff = () => {
      // TODO Переделать differenceInMinutes(props.workOrder.dateBegin, props.workOrder.dateBegin)
      const allTime = differenceInMinutes(props.workOrder.dateBegin, props.workOrder.dateBegin);
      const fromNow = allTime - differenceInMinutes(props.workOrder.dateBegin, new Date());
      leftPercent.value = (fromNow * 100) / allTime;
      timeLeft.value = Math.abs(differenceInMinutes(props.workOrder.dateBegin, new Date()));
    };

    const getLocomotiveCrewLabel = (workOrder: WorkOrder) => {
      const { crew } = workOrder;
      if (crew == null) {
        return 'Бригада не назначена';
      }
      return `${crew.find((c) => c.index === 1)!.employee.code} - ${crew.find((c) => c.index === 1)!.employee.surname}`;
    };

    const getLocomotiveLabel = (workOrder: WorkOrder) => {
      const { locomotive } = workOrder;
      if (locomotive == null) {
        return 'Локомотив не назначен';
      }

      return locomotive.name;
    };

    const getSectionLabel = (workOrder: WorkOrder) => {
      const orderInMinutes = differenceInMinutes(workOrder.dateEnd, workOrder.dateBegin);
      const date = new Date(1, 0, 0, 0, orderInMinutes, 0, 0);

      return `${workOrder.from.name} ${workOrder.to.name} (${toTime(date)})`;
    };

    const getTimeLeft = (time: number) => {
      if (time > 60) {
        return `${(time / 60).toFixed(0)} ч`;
      }

      return `${time} мин`;
    };

    checkTimeDiff();
    setInterval(checkTimeDiff, 2000);

    return {
      timeLeft,
      leftPercent,
      actions,
      getLocomotiveCrewLabel,
      getLocomotiveLabel,
      getSectionLabel,
      getTimeLeft,
      toTime,
      toDate,
    };
  },
});
