import { Type } from 'class-transformer';
import { SimpleModel } from '@/models/base/SimpleModel';
import { BusinessContract } from '@/models/BusinessContract';
import { BusinessOrder } from '@/models/orders/BusinessOrder';

export class OrderGroup {
  public id!: string;

  @Type(() => Date)
  public dateBegin!: Date;

  @Type(() => Date)
  public dateEnd!: Date;

  @Type(() => SimpleModel)
  public from!: SimpleModel;

  @Type(() => SimpleModel)
  public to!: SimpleModel;

  @Type(() => BusinessContract)
  public businessContract!: BusinessContract;

  @Type(() => BusinessOrder)
  public businessOrders = Array<BusinessOrder>();

  constructor(init?: Partial<OrderGroup>) {
    Object.assign(this, init);
  }
}
