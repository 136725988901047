





















import { defineComponent, onBeforeMount, reactive } from '@vue/composition-api';
import { Container } from '@/Container';
import { HttpService } from '@/services/network/HttpService';
import { AxiosResponse } from 'axios';
import { Locomotive } from '@/models/Locomotive';
import { UrlConstants } from '@/helpers/UrlConstants';
import * as L from 'leaflet';
import { LocomotivesResponse } from '@/models/locomotives/LocomotivesResponse';

export default defineComponent({
  name: 'LocomotivesOnMap',
  setup: () => {
    const httpService = Container.resolve<HttpService>("HttpService");

    const center = [51.196940, 71.393843];
    const attribution = '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors';
    const url = 'https://tile.thunderforest.com/outdoors/{z}/{x}/{y}.png?apikey=fe7b1ab861094cff84a14c509efd8a57';

    const reactiveData = reactive({
      locomotives: new Array<Locomotive>(),
    });

    onBeforeMount(async () => {
      let response: AxiosResponse<LocomotivesResponse>;
      const url = `${UrlConstants.baseApi}/v1/locomotives`;

      try {
        response = await httpService.get<LocomotivesResponse>(url, {
          constructorFunction: LocomotivesResponse,
        });
      } catch (e) {
        return;
      }
      reactiveData.locomotives = response.data.locomotives;
    });

    const icon = (locomotive: Locomotive) => {
      return L.divIcon({
        className: 'css-icon',
        html: `<div style="width: 200px;">
                <img src="img/icons/locomotive.png" height="32" width="32">
                <br>
                <span>${locomotive.name}</span>
              </div>`,
      });
    };

    return {
      center, attribution, url, reactiveData, icon,
    };
  },
});
