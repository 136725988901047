import { Locomotive } from '@/models/Locomotive';
import { SimpleModel } from '@/models/base/SimpleModel';
import { SmartDispatcherLocomotiveWorkModel } from '@/models/smart-dispatcher/SmartDispatcherLocomotiveWorkModel';

export class SmartDispatcherLocomotiveModel {
  public locomotive!: Locomotive

  public section: SimpleModel | null = null

  public work: SmartDispatcherLocomotiveWorkModel | null = null

  constructor(init?: Partial<SmartDispatcherLocomotiveModel>) {
    Object.assign(this, init);
  }
}
