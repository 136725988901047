import { SimpleModel } from '@/models/base/SimpleModel';
import { Depot } from '@/models/Depot';
import { LocoUnit } from '@/models/LocoUnit';
import { Position } from '@/models/Position';
import { Type } from 'class-transformer';

export class Locomotive extends SimpleModel {
  public code!: string

  public depot!: Depot

  public locoUnits: LocoUnit[] = []

  @Type(() => Position)
  public lastPosition!: Position

  constructor(init?: Partial<Locomotive>) {
    super();
    Object.assign(this, init);
  }
}
