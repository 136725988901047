
































































































































































































import {
  defineComponent, onBeforeMount, reactive, ref,
} from '@vue/composition-api';
import { Container } from '@/Container';
import { HttpService } from '@/services/network/HttpService';
import LocomotiveCard from '@/components/smart-dispatcher/LocomotiveCard.vue';
import LocomotiveCrewCard from '@/components/smart-dispatcher/LocomotiveCrewCard.vue';
import { SmartDispatcherLocomotiveCrewModel } from '@/models/smart-dispatcher/SmartDispatcherLocomotiveCrewModel';
import { SmartDispatcherLocomotiveModel } from '@/models/smart-dispatcher/SmartDispatcherLocomotiveModel';
import dateFormats from '@/helpers/DateFormats';
import { UrlConstants } from '@/helpers/UrlConstants';
import { AxiosResponse } from 'axios';
import { OrderGroup } from '@/models/orders/OrderGroup';
import { Type } from 'class-transformer';
import OrderGroupCard from '@/components/smart-dispatcher/OrderGroupCard.vue';
import RdMouseClickScrollable from '@/components/base/scrollable/RdDragScrollable.vue';
import LocomotivesOnMap from '@/components/maps/LocomotivesOnMap.vue';
import { LocomotivesResponse } from '@/models/locomotives/LocomotivesResponse';
import { LocomotiveCrewsResponse } from '@/models/locomotives/LocomotiveCrewsResponse';

class OrderGroupResponse {
  @Type(() => OrderGroup)
  public orderGroups = Array<OrderGroup>();
}

export default defineComponent({
  name: 'smart-dispatcher',
  components: {
    LocomotivesOnMap,
    RdMouseClickScrollable,
    LocomotiveCrewCard,
    OrderGroupCard,
    LocomotiveCard,
  },
  setup: () => {
    const httpService = Container.resolve<HttpService>("HttpService");
    const { toISO8601 } = dateFormats();

    const reactiveOrderGroups = ref(new Array<OrderGroup>());
    const selectedPanel = ref('SmartDispatcher');

    const reactiveData = reactive({
      locomotives: new Array<SmartDispatcherLocomotiveModel>(),
      crews: new Array<SmartDispatcherLocomotiveCrewModel>(),
    });

    onBeforeMount(() => {
      async function getBusinessOrders() {
        const date = toISO8601(new Date());
        const url = `${UrlConstants.baseApi}/v1/business-orders/${date}/groups`;
        let response: AxiosResponse<OrderGroupResponse>;
        try {
          response = await httpService.get<OrderGroupResponse>(url, {
            constructorFunction: OrderGroupResponse,
          });
        } catch (e) {
          return;
        }
        reactiveOrderGroups.value = response.data.orderGroups;
      }

      async function getLocomotives() {
        let response: AxiosResponse<LocomotivesResponse>;
        const url = `${UrlConstants.baseApi}/v1/locomotives`;

        try {
          response = await httpService.get<LocomotivesResponse>(url, {
            constructorFunction: LocomotivesResponse,
          });
        } catch (e) {
          return;
        }
        reactiveData.locomotives = response.data.locomotives.map((l) => new SmartDispatcherLocomotiveModel({
          locomotive: l,
          section: null,
          work: null,
        }));
      }

      async function getLocomotiveCrews() {
        let response: AxiosResponse<LocomotiveCrewsResponse>;
        const url = `${UrlConstants.baseApi}/v1/crew`;

        try {
          response = await httpService.get<LocomotiveCrewsResponse>(url, {
            constructorFunction: LocomotiveCrewsResponse,
          });
        } catch (e) {
          return;
        }
        reactiveData.crews = response.data.crews;
      }

      getBusinessOrders();
      getLocomotives();
      getLocomotiveCrews();
    });

    const openPanel = (panelName: string) => {
      selectedPanel.value = panelName;
    };

    return {
      reactiveOrderGroups,
      openPanel,
      selectedPanel,
      reactiveData,
    };
  },
});
