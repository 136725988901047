import { SimpleModel } from '@/models/base/SimpleModel';
import { Type } from 'class-transformer';
import { CrewTripPlan } from '@/models/orders/CrewTripPlan';
import { WorkOrder } from '@/models/orders/WorkOrder';

export class BusinessOrder {
  public id!: string

  public number!: string

  @Type(() => Date)
  public dateBegin!: Date

  @Type(() => Date)
  public dateEnd!: Date

  // @Type(() => Date)
  // public createDate!: Date

  @Type(() => SimpleModel)
  public workType!: SimpleModel

  @Type(() => SimpleModel)
  public from!: SimpleModel

  @Type(() => SimpleModel)
  public to!: SimpleModel

  @Type(() => SimpleModel)
  public trainSchedule!: SimpleModel

  // @Type(() => CrewTripPlan)
  // public crewTripPlans: CrewTripPlan[] = []

  @Type(() => WorkOrder)
  public workOrders: WorkOrder[] = []

  // public trafficKind!: SimpleModel

  // public locomotiveCrew: LocomotiveCrew | null = null

  // public locomotive: Locomotive | null = null

  // TODO уточнить может ли быть null
  // public section!: SimpleModel
  // В заказе получается не участок лежит,
  // а станция начала, станция окончания, я же правильно понял?

  constructor(init?: Partial<BusinessOrder>) {
    Object.assign(this, init);
  }
}
