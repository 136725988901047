




















































import { defineComponent, PropType } from '@vue/composition-api';
import { OrderGroup } from '@/models/orders/OrderGroup';
import dateFormats from '@/helpers/DateFormats';
import RdArrow from '@/components/base/RdArrow.vue';

export default defineComponent({
  name: 'TravelLine',
  components: { RdArrow },
  props: {
    orderGroup: {
      type: Object as PropType<OrderGroup>,
      required: true,
    },
  },
  setup: (props) => {
    const { toDate, toTime } = dateFormats();

    return {
      toTime,
      toDate,
    };
  },
});
