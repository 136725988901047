






































































































































import {
  defineComponent, PropType, reactive, ref,
} from '@vue/composition-api';
import dateFormats from '@/helpers/DateFormats';
import RdDropdownButton from '@/components/base/buttons/RdDropdownButton.vue';
import { SimpleModel } from '@/models/base/SimpleModel';
import { BusinessOrder } from '@/models/orders/BusinessOrder';
import CrewTripPlanCard from '@/components/smart-dispatcher/WorkOrderCard.vue';
import RdArrow from '@/components/base/RdArrow.vue';
import WorkOrderCard from '@/components/smart-dispatcher/WorkOrderCard.vue';
import RdDragScrollable from '@/components/base/scrollable/RdDragScrollable.vue';
import RdLeftRightFlexContent from '@/components/base/RdLeftRightFlexContent.vue';

export default defineComponent({
  name: 'OrderCard',
  components: {
    RdLeftRightFlexContent, RdDragScrollable, WorkOrderCard, RdArrow, CrewTripPlanCard, RdDropdownButton,
  },
  props: {
    order: {
      type: Object as PropType<BusinessOrder>,
      required: true,
    },
  },
  setup: (props, ctx) => {
    const {
      toTime,
      toDate,
    } = dateFormats();

    const actions = [
      new SimpleModel({
        id: 'Cancel',
        name: 'Отменить',
      }),
    ];

    return {
      actions,
      toTime,
      toDate,
    };
  },
});
