import { Type } from 'class-transformer';
import { WorkTaskType } from '@/models/orders/WorkTaskType';

export class WorkTask {
  public id!: string;

  @Type(() => Date)
  public createDate!: Date;

  @Type(() => Date)
  public planningExecuteDate!: Date;

  @Type(() => Date)
  public executeDate: Date | null = null;

  public workTaskType!: WorkTaskType;

  constructor(init?: Partial<WorkTask>) {
    Object.assign(this, init);
  }
}
