import { Type } from 'class-transformer';
import { EmployeeInCrew } from '@/models/trip/EmployeeInCrew';

export class SmartDispatcherLocomotiveCrewModel {
  public id = ''

  @Type(() => EmployeeInCrew)
  public crew = Array<EmployeeInCrew>();

  @Type(() => Date)
  public locomotiveReturnDate!: Date

  @Type(() => Date)
  public appearanceDate!: Date

  @Type(() => Date)
  public lastWorkEndTime!: Date

  constructor(init?: Partial<SmartDispatcherLocomotiveCrewModel>) {
    Object.assign(this, init);
  }
}
