











import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'RdDragScrollable',
  setup: () => {
    const setGrabbingMouse = (event: MouseEvent) => {
      const target = event.currentTarget as HTMLElement;
      target.style.cursor = 'grabbing';
    };

    const setGrabMouse = (event: MouseEvent) => {
      const target = event.currentTarget as HTMLElement;
      target.style.cursor = 'grab';
    };

    return {
      setGrabbingMouse,
      setGrabMouse,
    };
  },
});
