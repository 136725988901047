import { Type } from 'class-transformer';
import { SimpleModel } from '@/models/base/SimpleModel';
import { LocomotiveCrew } from '@/models/LocomotiveCrew';

export class CrewTripPlan {
  public id!: string;

  public number!: string;

  @Type(() => Date)
  public dateBegin!: Date;

  @Type(() => Date)
  public dateEnd!: Date;

  @Type(() => LocomotiveCrew)
  public crew: LocomotiveCrew | null = null;

  constructor(init?: Partial<CrewTripPlan>) {
    Object.assign(this, init);
  }
}
