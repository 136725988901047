







































































import { defineComponent, PropType, ref } from '@vue/composition-api';
import { differenceInHours, format } from 'date-fns';
import { SmartDispatcherLocomotiveCrewModel } from '@/models/smart-dispatcher/SmartDispatcherLocomotiveCrewModel';
import { Driver } from '@/models/Driver';
import dateFormats from '@/helpers/DateFormats';
import { EmployeeInCrew } from '@/models/trip/EmployeeInCrew';

export default defineComponent({
  name: 'LocomotiveCrewCard',
  props: {
    locomotiveCrewInfo: {
      type: Object as PropType<SmartDispatcherLocomotiveCrewModel>,
      required: true,
    },
  },
  setup: (props, ctx) => {
    const { toFullDateTime } = dateFormats();

    const crewDrivers = [...props.locomotiveCrewInfo.crew];

    const formatDriverInfo = (employeeInCrew: EmployeeInCrew) => {
      return `${employeeInCrew.employee.code} - ${employeeInCrew.employee.initials()}`;
    };

    const timeInHomePercent = ref(0);

    const computeTimeInHomePercent = () => {
      if (props.locomotiveCrewInfo.lastWorkEndTime == null) {
        timeInHomePercent.value = 0;
      }

      const maxHomeTime = 24;
      const hoursInHome = differenceInHours(Date.now(), props.locomotiveCrewInfo.lastWorkEndTime);

      timeInHomePercent.value = (hoursInHome * 100) / maxHomeTime;
    };

    const getDateTimeLabel = (date: Date) => {
      return toFullDateTime(date);
    };

    setInterval(computeTimeInHomePercent, 1000);
    computeTimeInHomePercent();

    return {
      crewDrivers,
      timeInHomePercent,
      formatDriverInfo,
      getDateTimeLabel,
    };
  },
});
